import {
  Menu,
  OrderItem,
  Restaurant,
  validateOrderItem,
  DispatchInfo,
  getDisplayableMenu,
  getDisplayableCatalog,
  getSupportedDispatchTypes,
  Catalog,
  CatalogPlatform,
} from '@wix/restaurants-client-logic';
import moment from 'moment-timezone';
import { StorageData } from '../../components/MainPage/controller';
import { setSelectedAddress } from '../../state/addressInformationForm/addressForm.actions';
import { addOrderCouponSuccess, setOrderComment, setOrderItems } from '../../state/cart/cart.actions';
import {
  setCheckoutStep,
  setContact,
  setDeliveryProviderEstimate,
  setDispatch,
  setLoyaltyPointsToRedeem,
  setSelectedAddressId,
} from '../../state/checkout/checkout.actions';
import { getVirtualDispatchTypeFromDispatch } from './dispatchLogic';

const MAX_MS_TO_USE_STORAGE_DATA = 30 * 60000;

export function loadStoredDataIntoStore(
  store: any,
  data: any,
  menu: Menu,
  catalog: Catalog,
  isCatalogsV3: boolean,
  restaurant: Restaurant,
  isMobile: boolean,
) {
  if (data) {
    const {
      orderItems,
      coupon,
      comment,
      checkoutStep,
      contact,
      dispatch,
      selectedAddressOption,
      timestamp,
      loyaltyPointsToRedeem,
      selectedAddressId,
      deliveryProvider,
    }: StorageData = JSON.parse(data);
    const currentTimestamp = moment().valueOf();

    if (timestamp + MAX_MS_TO_USE_STORAGE_DATA > currentTimestamp) {
      if (orderItems) {
        const updatedOrderItems = orderItems
          .map((orderItem) => {
            try {
              const { updatedOrderItem } = validateOrderItem(menu, orderItem);
              return updatedOrderItem;
            } catch (e) {
              if (e instanceof Error && e.message === 'Item was not found in menu') {
                return undefined;
              } else {
                throw e;
              }
            }
          })
          .filter(Boolean);

        store.dispatch(setOrderItems({ orderItems: updatedOrderItems as OrderItem[] }));
      }

      if (coupon) {
        store.dispatch(addOrderCouponSuccess(coupon));
      }

      if (comment) {
        store.dispatch(setOrderComment({ comment }));
      }

      if (checkoutStep) {
        store.dispatch(setCheckoutStep({ step: checkoutStep }));
      }

      if (contact) {
        store.dispatch(setContact({ contact }));
      }

      if (deliveryProvider) {
        store.dispatch(setDeliveryProviderEstimate(deliveryProvider));
      }

      if (dispatch) {
        const { locale, currency, timezone, deliveryInfos } = restaurant;
        const supportedDispatchTypes = getSupportedDispatchTypesWithoutSelector({
          deliveryInfos,
          menu,
          catalog,
          isCatalogsV3,
          locale,
          currency,
          timezone,
          isMobile,
          time: dispatch.time,
        });
        const dispatchType = getVirtualDispatchTypeFromDispatch(dispatch);

        if (supportedDispatchTypes.has(dispatchType)) {
          store.dispatch(setDispatch({ dispatch }));
        } else {
          store.dispatch(setCheckoutStep({ step: 'address-information' }));
        }
      }

      if (selectedAddressOption) {
        store.dispatch(setSelectedAddress({ address: selectedAddressOption }));
      }

      if (selectedAddressId) {
        store.dispatch(setSelectedAddressId({ id: selectedAddressId }));
      }

      if (loyaltyPointsToRedeem) {
        store.dispatch(setLoyaltyPointsToRedeem({ loyaltyPointsToRedeem }));
      }
    }
  }
}

export const getSupportedDispatchTypesWithoutSelector = ({
  deliveryInfos,
  menu,
  catalog,
  isCatalogsV3,
  locale,
  currency,
  timezone,
  isMobile,
  time,
}: {
  deliveryInfos: DispatchInfo[];
  menu: Menu;
  catalog: Catalog;
  isCatalogsV3: boolean;
  locale: string;
  currency: string;
  timezone: string;
  isMobile: boolean;
  time?: number;
}) => {
  const momentTime = moment(time).tz(timezone);
  const platform = isMobile ? CatalogPlatform.MOBILE_SITE : CatalogPlatform.SITE;
  const deliveryDisplayableMenu = isCatalogsV3
    ? getDisplayableCatalog(catalog, locale, currency, momentTime, platform, 'delivery')
    : getDisplayableMenu(
        menu,
        locale,
        currency,
        momentTime,
        platform === CatalogPlatform.MOBILE_SITE ? 'mobileweb' : 'web',
        'delivery',
      );
  return getSupportedDispatchTypes({ deliveryInfos, deliveryDisplayableMenu, timezone, getVirtualDispatchTypes: true });
};
